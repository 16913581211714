<template>
    <div>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#3F033B"
        spinner="bar-fade-scale"
      />
      <ServerError v-if="ServerError" />
      <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
  
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: #000">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap>
        <v-flex xs10 text-left>
          <span class="mainHeader">Sales Report</span>
        </v-flex>
        <v-flex xs2 text-right>
          <v-text-field
            background-color="white"
            v-model="keyword"
            color="#b234a9"
            clearable
            hide-details
            placeholder="Search"
            outlined
            class="rounded-xl"
            dense
            append-icon="mdi-magnify"
          >
          </v-text-field>
        </v-flex>
       
        <v-flex xs12 py-6 text-left>
          <v-card
            class="rounded-lg"
            elevation="0"
            :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
            :flat="$route.name == 'Dashboard' ? true : false"
          >
            <v-layout wrap pa-4>
              <v-flex xs12 v-if="dataList">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left subhed"><b>S.No.</b></th>
                        <th class="text-left subhed"><b>Bill No</b></th>
                        <th class="text-left subhed"><b>Date</b></th>
                        <th class="text-left subhed"><b>Customer Name</b></th>
                        <th class="text-left subhed"><b>Contact</b></th>
                        <th class="text-left subhed"><b>Gold Rate(₹)</b></th>
                        <th class="text-left subhed"><b>Sale Amt(₹)</b></th>
                        <th class="text-left subhed"><b>Old Gold Amt.(₹)</b></th>
                        <th class="text-left subhed"><b>Return Gold Amt.(₹)</b></th>
                        <!-- <th class="text-left subhed"><b>Discount(₹)</b></th> -->
                        <th class="text-left subhed"><b>Total Amt(₹)</b></th>
                        <th class="text-left subhed"><b>Status</b></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(value, i) in dataList"
                        :key="i"
                        class="table"
                      >
                        <!-- <td>{{ i + 1 }}</td> -->
                        <td>
                          <span v-if="page == 1">
                            {{ i + 1 }}
                          </span>
                          <span v-else>
                            {{ i + 1 + 20 * (page - 1) }}
                          </span>
                        </td>
                         <td>
                          <span v-if="value._id[0]">
                            {{ value._id[0].billNo }}</span
                          >
                          <span v-else>-</span>
                        </td>
                        <td >
                          <span v-if="value._id[0].create_date">{{
                            formatDate(value._id[0].create_date)
                          }}</span>
                          <span v-else>-</span>
                        </td>
                        
                        <td >
                          <span v-if="value._id[0]">{{
                            value._id[0].customerName
                          }}</span>
                          <span v-else>-</span>
                        </td>
                        <td >
                          <span v-if="value._id[0]">{{
                            value._id[0].mobileNumber
                          }}</span>
                          <span v-else>-</span>
                        </td>
                        <td>
                          <span v-if="value._id[0].goldRate">{{
                            value._id[0].goldRate
                          }}</span>
                          <span v-else>-</span>
                        </td>
                        <td>
                          <span v-if="value._id[0].saleTotal">{{
                            (value._id[0].saleTotal).toFixed(2) 
                          }}</span>
                          <span v-else>0</span>
                        </td>
                        <td>
                          <span v-if="value._id[0].oldGoldAmount">{{
                            (value._id[0].oldGoldAmount).toFixed(2) 
                          }}</span>
                          <span v-else>0</span>
                        </td>
                        <td>
                          <span v-if="value._id[0].returnAmount">{{
                            value._id[0].returnAmount
                          }}</span>
                          <span v-else>0</span>
                        </td>
                        <!-- <td>
                          <span v-if="value._id[0].billDiscount">{{
                            value._id[0].billDiscount
                          }}</span>
                          <span v-else>0</span>
                        </td> -->
                        <td>
                          <span v-if="value._id[0].payableAmount">{{ (value._id[0].payableAmount).toFixed(2) }}</span>
                          <span v-else>0</span>
                        </td>
                        <td>
                          <span v-if="value._id[0].paymentStatus">{{ value._id[0].paymentStatus }}</span>
                          <span v-else>0</span>
                        </td>
                        <!-- <td>
                          <v-icon
                            size="18"
                            color="#3F053C"
                            style="cursor: pointer"
                            @click="
                              $router.push('/SupplierDetail?id=' + value._id)
                            "
                            >mdi-eye</v-icon
                          >
                          <v-icon
                            size="18"
                            color="#3F053C"
                            class="px-4"
                            style="cursor: pointer"
                            @click="(curItem = value), (editdialogue = true)"
                            >mdi-pencil</v-icon
                          >
  
                          <v-icon
                            size="18"
                            color="#3F053C"
                            style="cursor: pointer"
                            @click="(deletedialog = true), (curId = value._id)"
                            >mdi-delete</v-icon
                          >
                        </td> -->
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-flex>
            </v-layout>
       
          </v-card>
          <v-layout wrap>
              <v-flex xs12 pt-4>
                <v-pagination
                  small
                  color="#3F053C"
                  v-model="page"
                  :length="Pagelength"
                >
                </v-pagination>
              </v-flex>
            </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </template>
  <script>
  import axios from "axios";
  export default {
    data() {
      return {
        appLoading: false,
        ServerError: false,
        showsnackbar: false,
        timeout: 2000,
        msg: null,
        page: 1,
        // Pagelength: null,
        dataList:[],
        keyword:null,

      juwellaryId: localStorage.getItem("juwellaryId")
      };
    },
    mounted() {
      this.getData();
    },
    computed: {
      appUser() {
        return this.$store.state.userData;
      },
    },
    watch: {
      keyword() {
        this.getData();
      },
      page(){
        this.getData();
      },
    },
    methods: {
     
      getData() {
        this.appLoading = true;
        axios({
          method: "get",
          url: "/sales/payment/getlist",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          params: {
            keyword: this.keyword,
            page: this.page,
            limit: 20,
          jewelleryId: this.juwellaryId,
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status == true) {
              this.dataList = response.data.payment;
              this.Pagelength = Math.ceil(response.data.totalLength / 20);
            } else {
              this.snackbar = true;
              this.msg = response.data.msg;
            }
  
            // this.msg = response.data.msg;
  
            // this.snackbar = true;
          })
          .catch((err) => {
            this.appLoading = false;
            console.log(err);
          });
      },
      formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      //  +
      // " , " +
      // hours +
      // ":" +
      // minutes +
      // " " +
      // ampm;

      return strTime;
    },
    },
  };
  </script>
  <style scoped>
  .v-text-field input {
    font-family: kumbhBold;
    }
  </style>
  